.footer-text {
  text-align: center;
  /* color: #868e96; */
}

.under-footer {
  min-height: 130px;
  width: 100%;
}
.footer-div {
  margin-top: 2rem;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
.footer-container {
  padding: 10px;
  background-color: rgb(32, 32, 32);
  color: #ffffff;
}

.footer-div a:link {
  text-decoration: none;
  color: rgb(180, 180, 180);
  font-size: 15px;
}

.footer-div a:visited {
  text-decoration: none;
  color: rgb(180, 180, 180);
}

.footer-div a:hover {
  text-decoration: underline;
  color: #ffffff;
}

.footer-div a:active {
  text-decoration: none;
  color: rgb(180, 180, 180);
}

@media screen and (max-width: 1380px) {
  .footer-text {
    font-size: 12px !important;
  }
}

@media screen and (max-height: 360px) {
  .under-footer {
    display: none;
  }
  .footer-text {
    font-size: 12px !important;
  }
  .footer-div {
    width: 100%;
    position: unset;
    font-size: 13px;
  }
}
